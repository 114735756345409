div.flow-node-img {
    border: solid 1px var(--ct-border-color);
    padding: .275rem;
    border-radius: .25rem;
    text-align: center;
    line-height: 100%;
}

div.flow-node-img img {
    width: 100%;
    height: 100%;
}

div.flow-node-img i {
    font-size:36px;
    
}


div.flow-card {
    width: 250px;
}