@import "icons.css";


:root {
    --ct-body-font-family: Nunito, sans-serif;
  --ct-body-font-size: 0.9rem;
  --ct-body-font-weight: 400;
  --ct-body-line-height: 1.5;
  --ct-body-color: #6c757d;
  --ct-body-bg: white;
  --ct-border-color: #dee2e6;
  --ct-text-muted: #98a6ad;
  --pliable-yellow: #FF9F00;
  --pliable-yellow-muted: #c37900;
  --ct-modal-backdrop-bg: #313a46;
  --ct-form-check-input-bg: #fff;
  --ct-form-check-input-border: 1px solid #dee2e6;
  --ct-form-check-input-checked-color: var(--ct-component-active-color);
  --ct-form-check-input-checked-bg-color: var(--pliable-yellow);
  --ct-form-check-input-checked-border-color: var(--ct-form-check-input-checked-bg-color);
  --ct-form-check-input-indeterminate-color: var(--ct-component-active-color);
  --ct-form-check-input-indeterminate-bg-color: var(--ct-component-active-bg);
  --ct-form-check-input-indeterminate-border-color: var(--ct-component-active-bg);
  --ct-menu-item: #6c757d;
  --ct-menu-item-hover: #313a46;
  --ct-primary: #727cf5;
  --ct-secondary: #6c757d;
  --ct-success: #0acf97;
  --ct-info: #39afd1;
  --ct-warning: #ffbc00;
  --ct-danger: #fa5c7c;
  --ct-light: #eef2f7;
  --ct-dark: #313a46;
  --ct-light-gray: #BABABA33;
  --ct-primary-rgb: 114, 124, 245;
  --ct-secondary-rgb: 108, 117, 125;
  --ct-success-rgb: 10, 207, 151;
  --ct-info-rgb: 57, 175, 209;
  --ct-warning-rgb: 255, 188, 0;
  --ct-danger-rgb: 250, 92, 124;
  --ct-light-rgb: 238, 242, 247;
  --ct-dark-rgb: 49, 58, 70;
  --ct-highlight: #CFF4B2;
}

.bg-primary {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-primary-rgb), var(--ct-bg-opacity)) !important;
}

.bg-pliable {
  --ct-bg-opacity: 1;
  background-color: var(--pliable-yellow) !important;
}

.bg-secondary {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-secondary-rgb), var(--ct-bg-opacity)) !important;
}

.bg-success {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-success-rgb), var(--ct-bg-opacity)) !important;
}

.bg-info {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-info-rgb), var(--ct-bg-opacity)) !important;
}

.bg-warning {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-warning-rgb), var(--ct-bg-opacity)) !important;
}

.bg-danger {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-danger-rgb), var(--ct-bg-opacity)) !important;
}

.bg-light {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-light-rgb), var(--ct-bg-opacity)) !important;
}

.bg-dark {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-dark-rgb), var(--ct-bg-opacity)) !important;
}

.bg-black {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-black-rgb), var(--ct-bg-opacity)) !important;
}

.bg-white {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-white-rgb), var(--ct-bg-opacity)) !important;
}

.alert-primary {
  color: #6c76e9;
  background-color: rgba(114, 124, 245, 0.18);
  border-color: rgba(114, 124, 245, 0.25);
}
.alert-primary .alert-link {
  color: #5057ac;
}

.alert-secondary {
  color: #676f77;
  background-color: rgba(108, 117, 125, 0.18);
  border-color: rgba(108, 117, 125, 0.25);
}
.alert-secondary .alert-link {
  color: #4c5258;
}

.alert-success {
  color: #0ac58f;
  background-color: rgba(10, 207, 151, 0.18);
  border-color: rgba(10, 207, 151, 0.25);
}
.alert-success .alert-link {
  color: #07916a;
}

.alert-info {
  color: #36a6c7;
  background-color: rgba(57, 175, 209, 0.18);
  border-color: rgba(57, 175, 209, 0.25);
}
.alert-info .alert-link {
  color: #287b92;
}

.alert-warning {
  color: #f2b300;
  background-color: rgba(255, 188, 0, 0.18);
  border-color: rgba(255, 188, 0, 0.25);
}
.alert-warning .alert-link {
  color: #b38400;
}

.alert-danger {
  color: #ee5776;
  background-color: rgba(250, 92, 124, 0.18);
  border-color: rgba(250, 92, 124, 0.25);
}
.alert-danger .alert-link {
  color: #af4057;
}

.alert-light {
  color: #e2e6eb;
  background-color: rgba(238, 242, 247, 0.18);
  border-color: rgba(238, 242, 247, 0.25);
}
.alert-light .alert-link {
  color: #a7a9ad;
}

.alert-dark {
  color: #2f3743;
  background-color: rgba(49, 58, 70, 0.18);
  border-color: rgba(49, 58, 70, 0.25);
}
.alert-dark .alert-link {
  color: #222931;
}

.action-icon {
  color: #98a6ad;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0 3px;
}
.action-icon:hover {
  color: #6c757d;
}
.list-group-item.active {
  background-color: var(--ct-primary);
  border-color: var(--ct-primary);
}

.text-primary {
    --ct-text-opacity: 1;
    color: rgba(var(--ct-primary-rgb), var(--ct-text-opacity)) !important;
  }
  
  .text-secondary {
    --ct-text-opacity: 1;
    color: rgba(var(--ct-secondary-rgb), var(--ct-text-opacity)) !important;
  }
  
  .text-success {
    --ct-text-opacity: 1;
    color: rgba(var(--ct-success-rgb), var(--ct-text-opacity)) !important;
  }
  
  .text-info {
    --ct-text-opacity: 1;
    color: rgba(var(--ct-info-rgb), var(--ct-text-opacity)) !important;
  }
  
  .text-warning {
    --ct-text-opacity: 1;
    color: rgba(var(--ct-warning-rgb), var(--ct-text-opacity)) !important;
  }
  
  .text-danger {
    --ct-text-opacity: 1;
    color: rgba(var(--ct-danger-rgb), var(--ct-text-opacity)) !important;
  }
  
  .text-light {
    --ct-text-opacity: 1;
    color: rgba(var(--ct-light-rgb), var(--ct-text-opacity)) !important;
  }
  
  .text-dark {
    --ct-text-opacity: 1;
    color: rgba(var(--ct-dark-rgb), var(--ct-text-opacity)) !important;
  }
  
  .m-0 {
    margin: 0 !important;
  }
  
  .m-1 {
    margin: 0.375rem !important;
  }
  
  .m-2 {
    margin: 0.75rem !important;
  }
  
  .m-3 {
    margin: 1.5rem !important;
  }
  
  .m-4 {
    margin: 2.25rem !important;
  }
  
  .m-5 {
    margin: 4.5rem !important;
  }
  
  .m-auto {
    margin: auto !important;
  }
  
  .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  
  .mx-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  
  .mx-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  
  .mx-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  
  .mx-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  
  .mx-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  
  .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  
  .my-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  
  .my-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  
  .my-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  
  .my-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  
  .my-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  
  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  
  .mt-0 {
    margin-top: 0 !important;
  }
  
  .mt-1 {
    margin-top: 0.375rem !important;
  }
  
  .mt-2 {
    margin-top: 0.75rem !important;
  }
  
  .mt-3 {
    margin-top: 1.5rem !important;
  }
  
  .mt-4 {
    margin-top: 2.25rem !important;
  }
  
  .mt-5 {
    margin-top: 4.5rem !important;
  }
  
  .mt-auto {
    margin-top: auto !important;
  }
  
  .me-0 {
    margin-right: 0 !important;
  }
  
  .me-1 {
    margin-right: 0.375rem !important;
  }
  
  .me-2 {
    margin-right: 0.75rem !important;
  }
  
  .me-3 {
    margin-right: 1.5rem !important;
  }
  
  .me-4 {
    margin-right: 2.25rem !important;
  }
  
  .me-5 {
    margin-right: 4.5rem !important;
  }
  
  .me-auto {
    margin-right: auto !important;
  }
  
  .mb-0 {
    margin-bottom: 0 !important;
  }
  
  .mb-1 {
    margin-bottom: 0.375rem !important;
  }
  
  .mb-2 {
    margin-bottom: 0.75rem !important;
  }
  
  .mb-3 {
    margin-bottom: 1.5rem !important;
  }
  
  .mb-4 {
    margin-bottom: 2.25rem !important;
  }
  
  .mb-5 {
    margin-bottom: 4.5rem !important;
  }
  
  .mb-auto {
    margin-bottom: auto !important;
  }
  
  .ms-0 {
    margin-left: 0 !important;
  }
  
  .ms-1 {
    margin-left: 0.375rem !important;
  }
  
  .ms-2 {
    margin-left: 0.75rem !important;
  }
  
  .ms-3 {
    margin-left: 1.5rem !important;
  }
  
  .ms-4 {
    margin-left: 2.25rem !important;
  }
  
  .ms-5 {
    margin-left: 4.5rem !important;
  }
  
  .ms-auto {
    margin-left: auto !important;
  }
  
  .m-n1 {
    margin: -0.375rem !important;
  }
  
  .m-n2 {
    margin: -0.75rem !important;
  }
  
  .m-n3 {
    margin: -1.5rem !important;
  }
  
  .m-n4 {
    margin: -2.25rem !important;
  }
  
  .m-n5 {
    margin: -4.5rem !important;
  }
  
  .mx-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  
  .mx-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  
  .mx-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  
  .mx-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  
  .mx-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  
  .my-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  
  .my-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  
  .my-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  
  .my-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  
  .my-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  
  .mt-n1 {
    margin-top: -0.375rem !important;
  }
  
  .mt-n2 {
    margin-top: -0.75rem !important;
  }
  
  .mt-n3 {
    margin-top: -1.5rem !important;
  }
  
  .mt-n4 {
    margin-top: -2.25rem !important;
  }
  
  .mt-n5 {
    margin-top: -4.5rem !important;
  }
  
  .me-n1 {
    margin-right: -0.375rem !important;
  }
  
  .me-n2 {
    margin-right: -0.75rem !important;
  }
  
  .me-n3 {
    margin-right: -1.5rem !important;
  }
  
  .me-n4 {
    margin-right: -2.25rem !important;
  }
  
  .me-n5 {
    margin-right: -4.5rem !important;
  }
  
  .mb-n1 {
    margin-bottom: -0.375rem !important;
  }
  
  .mb-n2 {
    margin-bottom: -0.75rem !important;
  }
  
  .mb-n3 {
    margin-bottom: -1.5rem !important;
  }
  
  .mb-n4 {
    margin-bottom: -2.25rem !important;
  }
  
  .mb-n5 {
    margin-bottom: -4.5rem !important;
  }
  
  .ms-n1 {
    margin-left: -0.375rem !important;
  }
  
  .ms-n2 {
    margin-left: -0.75rem !important;
  }
  
  .ms-n3 {
    margin-left: -1.5rem !important;
  }
  
  .ms-n4 {
    margin-left: -2.25rem !important;
  }
  
  .ms-n5 {
    margin-left: -4.5rem !important;
  }
  
  .p-0 {
    padding: 0 !important;
  }
  
  .p-1 {
    padding: 0.375rem !important;
  }
  
  .p-2 {
    padding: 0.75rem !important;
  }
  
  .p-3 {
    padding: 1.5rem !important;
  }
  
  .p-4 {
    padding: 2.25rem !important;
  }
  
  .p-5 {
    padding: 4.5rem !important;
  }
  
  .p-onboarding {
    padding: 6rem 9rem !important;
  }
  
  .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  
  .px-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  
  .px-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  
  .px-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  
  .px-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  
  .px-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  
  .py-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  
  .py-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  
  .py-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  
  .py-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  
  .py-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  
  .pt-0 {
    padding-top: 0 !important;
  }
  
  .pt-1 {
    padding-top: 0.375rem !important;
  }
  
  .pt-2 {
    padding-top: 0.75rem !important;
  }
  
  .pt-3 {
    padding-top: 1.5rem !important;
  }
  
  .pt-4 {
    padding-top: 2.25rem !important;
  }
  
  .pt-5 {
    padding-top: 4.5rem !important;
  }
  
  .pe-0 {
    padding-right: 0 !important;
  }
  
  .pe-1 {
    padding-right: 0.375rem !important;
  }
  
  .pe-2 {
    padding-right: 0.75rem !important;
  }
  
  .pe-3 {
    padding-right: 1.5rem !important;
  }
  
  .pe-4 {
    padding-right: 2.25rem !important;
  }
  
  .pe-5 {
    padding-right: 4.5rem !important;
  }
  
  .pb-0 {
    padding-bottom: 0 !important;
  }
  
  .pb-1 {
    padding-bottom: 0.375rem !important;
  }
  
  .pb-2 {
    padding-bottom: 0.75rem !important;
  }
  
  .pb-3 {
    padding-bottom: 1.5rem !important;
  }
  
  .pb-4 {
    padding-bottom: 2.25rem !important;
  }
  
  .pb-5 {
    padding-bottom: 4.5rem !important;
  }
  
  .ps-0 {
    padding-left: 0 !important;
  }
  
  .ps-1 {
    padding-left: 0.375rem !important;
  }
  
  .ps-2 {
    padding-left: 0.75rem !important;
  }
  
  .ps-3 {
    padding-left: 1.5rem !important;
  }
  
  .ps-4 {
    padding-left: 2.25rem !important;
  }
  
  .ps-5 {
    padding-left: 4.5rem !important;
  }
a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a.btn:hover {
    text-decoration: none;
}



.h-100 {
    height: 100% !important;
  }

/* Fix grid */
.row {
    margin-left: 0px;
    margin-right: 0px;
}
.row>* {
    padding-right: 0px;
    padding-left: 0px;
}

@font-face {
    font-family: "Nunito";
    src: url("./assets/fonts/Nunito-Light.eot");
    src: local("Nunito Light"), local("Nunito-Light"), url("./assets/fonts/Nunito-Light.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/Nunito-Light.woff") format("woff"), url("./assets/fonts/Nunito-Light.ttf") format("truetype"), url("./assets/fonts/Nunito-Light.svg#Roboto") format("svg");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "Nunito";
    src: url("./assets/fonts/Nunito-Regular.eot");
    src: local("Nunito Regular"), local("Nunito-Regular"), url("./assets/fonts/Nunito-Regular.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/Nunito-Regular.woff") format("woff"), url("./assets/fonts/Nunito-Regular.ttf") format("truetype"), url("./assets/fonts/Nunito-Regular.svg#Roboto") format("svg");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "Nunito";
    src: url("./assets/fonts/Nunito-SemiBold.eot");
    src: local("Nunito SemiBold"), local("Nunito-SemiBold"), url("./assets/fonts/Nunito-SemiBold.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/Nunito-SemiBold.woff") format("woff"), url("./assets/fonts/Nunito-SemiBold.ttf") format("truetype"), url("./assets/fonts/Nunito-SemiBold.svg#Roboto") format("svg");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "Nunito";
    src: url("./assets/fonts/Nunito-Bold.eot");
    src: local("Nunito Bold"), local("Nunito-Bold"), url("./assets/fonts/Nunito-Bold.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/Nunito-Bold.woff") format("woff"), url("./assets/fonts/Nunito-Bold.ttf") format("truetype"), url("./assets/fonts/Nunito-Bold.svg#Roboto") format("svg");
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins/Poppins-Light.ttf");
    font-weight: 400;
    font-style: normal;
  }

body {
    margin: 0;
    font-family: var(--ct-body-font-family);
    font-size: var(--ct-body-font-size);
    font-weight: var(--ct-body-font-weight);
    line-height: var(--ct-body-line-height);
    color: var(--ct-body-color);
    text-align: var(--ct-body-text-align);
    background-color: rgb(254, 252, 250);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100%;
    max-height: 100%;
  }

  .btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.45rem 0.9rem;
    font-size: 0.9rem;
    border-radius: 0.15rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }

  .btn-sm, .btn-group-sm > .btn {
    padding: 0.28rem 0.8rem;
    font-size: 0.875rem;
    border-radius: 0.15rem;
  }

  a[role="button"] {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .btn-pliable {
    background-color: #FF9F00;
    color: #fff;
    border-color:  #FF9F00;
  }
  
  .btn-pliable:hover {
    background-color: #e58f00;
    color: #fff;
  
    border-color: #e58f00;
  }
  
  .btn-pliable:disabled {
    color: #fff;
    background-color: #c37900;
    border-color: #c37900;
  }
  
  .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64;
  }

  .btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d;
  }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }

  .btn-success {
    color: #fff;
    background-color: #0acf97;
    border-color: #0acf97;
  }
  .btn-success:hover {
    color: #fff;
    background-color: #09b080;
    border-color: #08a679;
  }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #fff;
    background-color: #09b080;
    border-color: #08a679;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(47, 214, 167, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(47, 214, 167, 0.5);
  }
  .btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #08a679;
    border-color: #089b71;
  }
  .btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(47, 214, 167, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(47, 214, 167, 0.5);
  }
  .btn-success:disabled, .btn-success.disabled {
    color: #fff;
    background-color: #0acf97;
    border-color: #0acf97;
  }
  .btn-danger {
    color: #fff;
    background-color: #fa5c7c;
    border-color: #fa5c7c;
  }
  .btn-danger:hover {
    color: #fff;
    background-color: #d54e69;
    border-color: #c84a63;
  }

  .btn-light {
    color: #313a46;
    background-color: #eef2f7;
    border-color: #eef2f7;
  }
  .btn-light:hover {
    color: #313a46;
    background-color: #f1f4f8;
    border-color: #f0f3f8;
  }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #313a46;
    background-color: #f1f4f8;
    border-color: #f0f3f8;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(210, 214, 220, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(210, 214, 220, 0.5);
  }
  .btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
    color: #313a46;
    background-color: #f1f5f9;
    border-color: #f0f3f8;
  }
  .btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(210, 214, 220, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(210, 214, 220, 0.5);
  }
  .btn-light:disabled, .btn-light.disabled {
    color: #313a46;
    background-color: #eef2f7;
    border-color: #eef2f7;
  }

  .text-muted {
    --ct-text-opacity: 1;
    color: var(--ct-text-muted) !important;
  }

  .font-13 {
    font-size: 13px;
  }


.content {
    position: absolute;
    top: 70px;
    width: 100%;
}

.section {
    padding: 1rem;
}

.section.spaced {
  padding: 2rem 5rem;
}

.border-bottom {
    border-bottom: solid 1px var(--ct-border-color);
}

.border-right {
    border-right: solid 1px var(--ct-border-color);
}

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

.content-inner {
    /* Scroll to keep the header and footer in view */
    overflow-y: auto;
    height: calc(100vh - 70px);
    width: 100%;
    overflow-x: hidden;

}

.back-breadcrumb {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 1rem;
}

.content-inner.no-scroll {
  overflow:hidden;
}

.content-inner.has-subnav {
    margin-top: 40px;
    height: calc(100vh - 70px - 40px);
}

.content-inner.has-footer {
  height: calc(100vh - 70px - 34px);
}

.content-inner.has-footer.has-subnav {
  height: calc(100vh - 70px - 34px - 40px);
}

footer.footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 34px;
  width: 100%;
  background-color: rgb(254, 252, 250);
  line-height: 34px;
  padding-right: .5rem;
  padding-left: .5rem;
}




.arrow-none:after {
    display: none !important;
  }


.flex-1 {
    flex: 1 !important;
  }

  .dropdown-toggle {
    white-space: nowrap;
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.2125em;
    vertical-align: 0.2125em;
    content: "";
    border-top: 0.25em solid;
    border-right: 0.25em solid transparent;
    border-bottom: 0;
    border-left: 0.25em solid transparent;
  }
  .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  

.navbar-top {
    padding: 0 12px;
    background-color: white;
    border-bottom: solid 1px var(--ct-border-color);
    min-height: 70px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1001;
  }
  
  .navbar-top.sidebar-compact {
    left: 70px;
  }
  
  .navbar-top.sidebar-disabled {
    left: 0px;
  
  }
  
  .navbar-top .topbar-left {
    background-color: var(--ct-bg-topbar);
    height: 70px;
    position: fixed;
    z-index: 1;
    width: 260px;
    text-align: center;
    top: 0;
    left: 0;
  }
  
  .navbar-top .topbar-left .logo i {
    display: none;
  }
  .navbar-top .topbar-menu {
    position: relative;
    z-index: 1;
  }
  .navbar-top .topbar-menu li {
    float: left;
    max-height: 70px;
  }
  .navbar-top .topbar-menu li .show.nav-link {
    color: #6c757d;
  }
  .navbar-top .topbar-menu .nav-link {
    padding: 0;
    color: #98a6ad;
    min-width: 32px;
    display: block;
    text-align: center;
    margin: 0 10px;
    position: relative;
  }
  .navbar-top .app-search {
    position: static;
    overflow-y: hidden;
  }
  .navbar-top .app-search form {
    padding: calc(32px * 0.5) 5px calc(32px * 0.5) 0;
    overflow: hidden;
    max-width: 320px;
  }
  
  .navbar-top .nav-link.active {
    color: var(--ct-primary);
  }

  /* Notification */
.notification-list {
    margin-left: 0;
  }
  .notification-list .noti-title {
    background-color: transparent;
    padding: 15px 20px;
  }
  .notification-list .noti-icon {
    font-size: 22px;
    vertical-align: middle;
    line-height: 70px;
  }
  .notification-list .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 22px;
    right: 6px;
    border-radius: 50%;
    height: 7px;
    width: 7px;
    background-color: #fa5c7c;
  }
  .notification-list .notify-item {
    padding: 10px 20px;
  }
  .notification-list .notify-item.unread-noti {
    background-color: #f3f5fb;
  }
  .notification-list .notify-item.read-noti {
    background-color: transparent;
    border: 1px solid #eef2f7;
  }
  .notification-list .notify-item .card-body {
    padding: 14px;
  }
  .notification-list .notify-item .card-body .noti-close-btn {
    position: absolute;
    top: 3px;
    right: 5px;
  }
  .notification-list .notify-item .card-body .noti-item-title {
    margin: 0 0 2px;
  }
  .notification-list .notify-item .card-body .noti-item-title, .notification-list .notify-item .card-body .noti-item-subtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .notification-list .notify-item .notify-icon {
    float: left;
    height: 36px;
    width: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
  }
  .notification-list .notify-item .notify-details {
    margin-bottom: 0;
    overflow: hidden;
    margin-left: 45px;
  }
  .notification-list .notify-item .notify-details b {
    font-weight: 500;
  }
  .notification-list .notify-item .notify-details small, .notification-list .notify-item .notify-details .small {
    display: block;
  }
  .notification-list .notify-item .user-msg {
    margin-left: 45px;
    white-space: normal;
    line-height: 16px;
  }
  .notification-list .topbar-dropdown-menu .notify-item {
    padding: 7px 20px;
  }
  
  .profile-dropdown {
    min-width: 170px;
  }
  .profile-dropdown i, .profile-dropdown span {
    vertical-align: middle;
  }
  
  .profile-dropdown.show {
    right: 0px !important;
  }
  
  .nav-user {
    padding: calc(31px * 0.5) 20px calc(31px * 0.5) 57px !important;
    text-align: left !important;
    position: relative;
    background-color: #fafbfd;
    border: 1px solid #f1f3fa;
    border-width: 0 1px;
    min-height: 70px;
  }
  .nav-user .account-user-avatar {
    position: absolute;
    top: calc(38px * 0.5);
    left: 15px;
  }
  .nav-user .account-user-avatar img {
    height: 32px;
    width: 32px;
  }
  .nav-user .account-position {
    display: block;
    font-size: 12px;
    margin-top: -3px;
  }
  .nav-user .account-user-name {
    display: block;
    font-weight: 600;
  }

 

  .rounded-circle {
    border-radius: 50% !important;
  }

  .avatar-sm {
    height: 3rem;
    width: 3rem;
  }
  
  .avatar-md {
    height: 4.5rem;
    width: 4.5rem;
  }

  .avatar-xs {
    height: 2rem;
    width: 2rem;
  }


  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      -webkit-transition: none;
      transition: none;
    }
  }
  
  .offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: var(--ct-modal-backdrop-bg);
  }
  .offcanvas-backdrop.fade {
    opacity: 0;
  }
  .offcanvas-backdrop.show {
    opacity: 0.7;
  }
  
  .offcanvas-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 1rem 1rem;
  }
  .offcanvas-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem;
  }
  
  .offcanvas-title {
    margin-bottom: 0;
    line-height: 1.5;
  }
  
  .offcanvas-body {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    padding: 1rem 1rem;
    overflow-y: auto;
  }
  
  .offcanvas-start {
    top: 0;
    left: 0;
    width: 400px;
    border-right: 1px solid var(--ct-border-color);
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  
  .offcanvas-end {
    top: 0;
    right: 0;
    width: 600px;
    border-left: 1px solid var(--ct-border-color);
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  
  .offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-bottom: 1px solid var(--ct-border-color);
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  
  .offcanvas-bottom {
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-top: 1px solid var(--ct-border-color);
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  
  .offcanvas.show {
    -webkit-transform: none;
            transform: none;
  }

  .table {
    background-color: white;
    border: solid 1px var(--ct-border-color);
    color: var(--ct-body-color);
  }

  .table.no-border {
    border: none;
    border-color: var(--ct-border-color);
  }

  .table.no-border tr:last-child td {
    border: none;
  }


  .table-fixed {
    table-layout: fixed;
    width: 100%;
  }

  .table-small {
    font-size: 12px;
  }
  


  .table-centered th, .table-centered td {
    vertical-align: middle !important;
  }

  .table>:not(:last-child)>:last-child>* {
    border-bottom-color: var(--ct-border-color);
  }
  

  .form-check {
    display: block;
    min-height: 1.35rem;
    padding-left: 1.612em;
    margin-bottom: 0;
  }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.612em;
  }
  
  .form-check-input {
    cursor: pointer;
    width: 1.112em;
    height: 1.112em;
    margin-top: 0.194em;
    vertical-align: top;
    background-color: var(--ct-form-check-input-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: var(--ct-form-check-input-border);
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    -webkit-print-color-adjust: exact;
            color-adjust: exact;
    -webkit-transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }
  .form-check-input:checked {
    background-color: var(--ct-form-check-input-checked-bg-color);
    border-color: var(--ct-form-check-input-checked-border-color);
  }



  label:not(.form-check-label) {
    font-family: "Poppins";
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Poppins";
  }

 h2 {
    font-size: 20px;
    margin-bottom: 1.2rem;
 }

 h3 {
    font-size: 17px;
 }

 h4 {
    font-size: 15px;
 }

 h5 {
    font-size: 14px;
 }

 h6 {
    font-size: 13px;
 }

 .font-poppins {
    font-family: "Poppins";
 }


 nav.subnav {
    /* background-color: white; */

    border-bottom: solid 1px var(--ct-border-color);
    position: fixed;
    top: 70px;
    z-index: 1000;
    height: 40px;
    padding-bottom: 0px;
    padding-left: 1rem;
    display: flex;
    width: 75%;

  }


  nav.subnav.toolbar .search {
    height: 100%;
    border: none;
    border-left: solid 1px var(--ct-border-color);
    border-right: solid 1px var(--ct-border-color);
    width: 300px;
  }

  nav.subnav.toolbar .search input {
    border: none;
    padding: 0px .5rem;
    width: 100%;
    font-size: 13px;
    line-height: 39px;
  }

  nav.subnav.toolbar .search input:focus {
    outline: none;
  }

  nav.subnav .actions {
    flex: 1;
    text-align: right;
    margin-right: 1rem;
  }

  nav.subnav .actions .btn {
    padding: 0px 2rem;
    height: 32px;
    line-height: 32px;
    margin-top: 4px;

  }

  nav.subnav .nav-link {
    color: var(--ct-menu-item);
    font-size: 13px;
    height: 40px;
    line-height: 40px;
    padding: 0px .75rem;
  }

  
  nav.subnav.toolbar .nav-link {
    
    padding-top: 0px;
    padding-bottom: 0px;
    height: 32px;
    line-height: 32px;
    position: relative;
    border-radius: 8px;
    border-left: solid 1px transparent;
    border-right: solid 1px transparent;
    border-top: solid 1px transparent;
    margin-top: 4px;
    margin-left: .25rem;
    margin-right: .25rem;
    padding-left: .75rem;
    padding-right: .75rem;
  }


  nav.subnav.toolbar .nav-link.active {
    height: 36px;
    color: var(--ct-menu-item);
    background-color: white;
    border-left: solid 1px var(--ct-border-color);
    border-right: solid 1px var(--ct-border-color);
    border-top: solid 1px var(--ct-border-color);
    z-index: 2;
    border-bottom: solid 1px white;
    box-shadow: 0px 1px 6px 2px #ccc;
    border-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  nav.subnav .nav-link.active {
    color: var(--ct-primary);
  }

  nav.subnav .nav-link.highlight {
    background-color: var(--ct-highlight);
  }

  nav.subnav .nav-link.highlight.active {
    background-color: white;
  }
  
  nav.subnav .nav-link:hover {
    color: var(--ct-menu-item);
    text-decoration: none;
  }

  nav.subnav .nav-link  .subnav-drawer {
    display: none;
    padding: 1rem;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  nav.subnav .nav-link .shadow-hider {
    position: absolute;
    top: 28px;
    box-shadow: none;
    height: 10px;
    left: 0px;
    width: 100%;
    background-color: white;
    z-index: 2;

    display: none;
  }

  nav.subnav .nav-link.active .shadow-hider {
    display: block;
  }

nav.subnav .nav-link.active .subnav-drawer {
  display: block;
  position: absolute;
  top: 35px;
  left: -1px;
  background-color: white;
  width: 650px;
  border-bottom: solid 1px var(--ct-border-color);
  border-left: solid 1px var(--ct-border-color);
  border-right: solid 1px var(--ct-border-color);
  z-index: 1;
  box-shadow: 0px 1px 6px 2px #ccc;
}
  


  .inline-edit {
    border-color: transparent;
    padding: 0px;
    resize: none;
    font-size: 13px;
  }
  
  .inline-edit:focus, .inline-edit:hover {
    border-color: var(--ct-border-color);
  }
  
  .form-switch .form-check-label {
    margin-left: 0.5rem;
  }

  .flex-1 {
    flex: 1 !important;
  }

  .d-flex {
    display: flex;
  }

  .input-code {
    font-family: monospace;
    font-size: 13px;
    line-height: 24px;
  }

  .float-right {
    float: right;
  }



  .was-validated .form-control:valid, .form-control.is-valid {
    border-color: #0acf97;
    padding-right: calc(1.5em + 0.9rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.225rem) center;
    background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
  }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #0acf97;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
  }
  
  .was-validated textarea.form-control:valid, textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.9rem);
    background-position: top calc(0.375em + 0.225rem) right calc(0.375em + 0.225rem);
  }
  
  .was-validated .form-select:valid, .form-select.is-valid {
    border-color: #0acf97;
  }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.95rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%238b96a0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.9rem center, center right 2.7rem;
    background-size: 14px 10px, calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
  }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #0acf97;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
  }
  
  .was-validated .form-check-input:valid, .form-check-input.is-valid {
    border-color: #0acf97;
  }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #0acf97;
  }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
  }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #0acf97;
  }


  i.floating-icon {
    position: absolute;
    right: 1.5rem;
    font-size: 2rem;
    opacity: 0.3;
  }