.reactflow-handle-interactive {
    width: 80px !important;
    height: 25px !important;
    background: var(--ct-light) !important;
    border: solid 1px var(--ct-card-border-color) !important;
    border-radius: 0.375rem !important;
    line-height: 25px;
    text-align: center;
    font-size: 12px;
    z-index: 10;
  }
  
  .reactflow-handle-interactive.top {
    top: -20px !important;
  }
  
  .reactflow-handle-interactive.bottom {
    bottom: -20px !important;
  }
  
  .reactflow-handle-interactive:hover {
    cursor: pointer !important;
    background: var(--ct-primary) !important;
    color: white !important;
  }

  .reactflow-handle-interactive.is-connectable {
    opacity: 1.0 !important;
  }

  .reactflow-handle-interactive.not-connectable {
    opacity: 0.3 !important;
  }
  
  .datamodel-addnew-cta {
    background: none;
    border: dashed 1px var(--ct-card-border-color);
    width: 250px;
    padding: 1.5rem !important;
    border-radius: .25rem;
    height: 80px;
  }
  
  .datamodel-node {
    width: 250px;
  }
  
  .datamodel-addnew-cta:hover {
    border-color: var(--ct-text-title-color);
    cursor: pointer;
  }