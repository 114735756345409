.page-title-box {
    display: flex;
    height: 70px;
    padding: 15px 1rem;
}

.page-title-left {
    flex: 1;
}

.page-title-box h1.page-title {
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins";
    margin-bottom: 0px;
}

.page-subtitle {
    color: var(--ct-text-muted);
    font-size: 13px;
}

.page-title-right {
    flex: 1;
    text-align: right;
    align-items: center;
}

.page-title-icon {
    flex-basis: 50px;
}