.datasource-box {
    position: relative;
    border-radius: .2rem;

}

.datasource-box .box-header {
    line-height: 50px;
    border-bottom: solid 5px var(--ct-gray-200);
    background-color: #DEE2E6;
    font-weight: 400;
    display: flex;
}

.datasource-box .box-header a {
    color: black;
    text-decoration: none;
}

.datasource-box .box-header .left-side {
    flex-basis: 48px;
}

.datasource-box .box-header .right-side {
    padding-left: 1rem;
    flex: 1;
    line-height: 50px;
    align-items: center;
    font-weight: bold;
}


.datasource-box .box-header a:hover {
    text-decoration:underline;
    color: black;
}

.datasource-box ul.datasets {
    margin: 0px;
    padding: 0px;

}

.datasource-box .box-header h5 {
    font-family: "Poppins";
    font-weight: 100;
}


.datasource-box ul.datasets li {
    list-style-type: none;
    padding: .375rem .75rem;
    background-color: white;
    border-bottom: solid 1px var(--ct-border-color);
}

/* .datasource-box ul.datasets li:hover {
    cursor:pointer;
    background-color: var(--ct-gray-300);
} */

.datasource-box ul.datasets li.selected {
    background-color: var(--ct-primary);
    color: white;
}
.list-group-item.secondary-header {
    background-color: var(--ct-gray-200);
}

.list-group.compact .list-group-item {
    padding: .375rem .75rem;
}

.link-node {
    background: var(--ct-gray-300);
    padding: .375px;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 10px;
    line-height: 20px;
    color: white;
    border: none;
    cursor: default;
}

.link-node.active {
    background: var(--pliable-yellow);
}

.link-node.active:hover {
    cursor:pointer;
    color: white;
}